import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import VueI18n from "@/libs/i18n";

import dayjs from "dayjs";
import tr from "dayjs/locale/tr";
import az from "dayjs/locale/az";
import ru from "dayjs/locale/az";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useOrdersList() {
  // Use toast
  const toast = useToast();

  const refInvoiceListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "plantname", label: VueI18n.t("plantName"), sortable: false },
    { key: "adduser", label: VueI18n.t("addUser"), sortable: false },
    {
      key: "duedate",
      label: VueI18n.t("dueDate"),
      sortable: false,
      formatter: (val) =>
        `${dayjs(val).locale(`${VueI18n.locale}`).format("DD MMMM YYYY")}`,
    },
    {
      key: "orderdate",
      label: VueI18n.t("orderDate"),
      sortable: false,
      formatter: (val) =>
        `${dayjs(val).locale(`${VueI18n.locale}`).format("DD MMMM YYYY")}`,
    },
    { key: "actions", label: VueI18n.t("actions") },
  ];
  const perPage = ref(10);
  const totalOrders = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [5, 10, 25, 50];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const statusFilter = ref(null);
  const allOrders = ref([]);
  const filteredOrders = ref([]);
  const allUsers = ref([]);
  const selectedUser = ref(null);
  const dataMeta = computed(() => {
    const localItemsCount = filteredOrders.value.length;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to:
        perPage.value * (currentPage.value - 1) +
          perPage.value * currentPage.value <
        localItemsCount
          ? perPage.vale
          : localItemsCount,
      of: allOrders.value.length,
    };
  });

  const refetchData = () => {
    if (selectedUser.value) {
      filteredOrders.value = allOrders.value.filter((x) =>
        x.adduser.toLowerCase().includes(selectedUser.value.toLowerCase())
      );
    } else {
      filteredOrders.value = allOrders.value;
    }
  };

  watch([currentPage, perPage, searchQuery, statusFilter, selectedUser], () => {
    refetchData();
  });

  const fetchOrders = async (ctx, callback) => {
    var orders = await store
      .dispatch("ordersModule/fetchOrders")

      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("xFetchError", { value: VueI18n.t("orders") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    allUsers.value = [
      { value: "", label: VueI18n.t("selectUser") },
      ...orders
        .map((x) => ({ value: x.adduser, label: x.adduser }))
        .filter((x, i, self) => self.findIndex((t) => t.key === x.key) === i),
    ];
    allOrders.value = orders;
    filteredOrders.value = orders;
    refetchData();
    return orders;
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveInvoiceStatusVariantAndIcon = (status) => {
    if (status === "Partial Payment")
      return { variant: "warning", icon: "PieChartIcon" };
    if (status === "Paid")
      return { variant: "success", icon: "CheckCircleIcon" };
    if (status === "Downloaded")
      return { variant: "info", icon: "ArrowDownCircleIcon" };
    if (status === "Draft") return { variant: "primary", icon: "SaveIcon" };
    if (status === "Sent") return { variant: "secondary", icon: "SendIcon" };
    if (status === "Past Due") return { variant: "danger", icon: "InfoIcon" };
    return { variant: "secondary", icon: "XIcon" };
  };

  const resolveClientAvatarVariant = (status) => {
    if (status === "Partial Payment") return "primary";
    if (status === "Paid") return "danger";
    if (status === "Downloaded") return "secondary";
    if (status === "Draft") return "warning";
    if (status === "Sent") return "info";
    if (status === "Past Due") return "success";
    return "primary";
  };

  return {
    fetchOrders,
    tableColumns,
    perPage,
    currentPage,
    totalOrders,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInvoiceListTable,
    allOrders,
    filteredOrders,
    allUsers,
    statusFilter,
    selectedUser,

    resolveInvoiceStatusVariantAndIcon,
    resolveClientAvatarVariant,

    refetchData,
  };
}
