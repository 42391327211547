<template>
  <order-list />
</template>

<script>
import OrderList from "./OrderList/OrderList";
export default {
  components: {
    OrderList,
  },
  data: function () {
    return {
      orders: [],
    };
  },
  mounted() {},
};
</script>

<style></style>
